import { MAINTAIN_PERMISSION, CONTAINER_FILLING_ADMINISTRATION } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Administration", "has-filter-inactive": false, "has-active": false } }, [_c("text-field", { key: "Facility", attrs: { "data-index": "facility", "title": "Facility", "width": 210, "sorter": true } }), _c("text-field", { key: "ManufDesc", attrs: { "data-index": "manufDesc", "title": "Description" } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListAdministration",
  inject: ["can"],
  data() {
    return {
      permissions: {
        maintain: MAINTAIN_PERMISSION
      }
    };
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListAdministration = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-administration" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "page": _vm.page, "name": "cf.syscontrols", "api-url": _vm.apiUrl, "edit-route": "/container-filling/administration/:id" } }, [_c("list-administration")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListAdministration
  },
  data() {
    return {
      page: CONTAINER_FILLING_ADMINISTRATION,
      ListAdministration,
      apiUrl,
      itemsMenu: [
        {
          key: "administration",
          title: "Administration",
          path: ""
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
